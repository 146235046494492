import { throwError } from './devToolHelpers';

export const roll = (odds) => {
  const value = Math.random();
  return value <= odds;
};

export const parseToSearchParamsLookup = (searchParams) => {
  const lookup = {};
  for (let key of searchParams.keys()) {
    lookup[key] = searchParams.get(key);
  }
  return lookup;
};
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 */
export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return 'unknown';
};

export const doArraysContainSameElementValues = (arr1, arr2, config = {}) => {
  const { checkIndexOrderMatches } = config;

  if (arr1.length !== arr2.length) {
    return false;
  }

  if (checkIndexOrderMatches) {
    return arr1.every((el, i) => el === arr2[i]);
  } else {
    return arr1.every((el) => arr2.includes(el));
  }
};

export const filterDuplicates = (array) => array.filter((el, i) => array.indexOf(el) === i);

export const returnDifferentElements = (arr1, arr2) => {
  const diffEls = [];
  arr1.forEach((el) => {
    if (!arr2.includes(el)) {
      diffEls.push(el);
    }
  });
  return diffEls;
};

export const separateIntoAddedAndRemovedElements = (currentArray, prevArray) => {
  const added = returnDifferentElements(currentArray, prevArray);
  const removed = returnDifferentElements(prevArray, currentArray);
  return [added, removed];
};

export const pickOne = (availablePicks = []) => {
  const countOfAvailablePicks = availablePicks.length;
  if (countOfAvailablePicks === 0) {
    return null;
  }

  const randomSelection = Math.floor(Math.random() * countOfAvailablePicks);
  return availablePicks[randomSelection];
};

export const pluralize = (count, string, config = {}) => {
  const { prefixCount } = config;

  if (string === 'have') {
    return `${prefixCount ? `${count} ` : ''}${count === 1 ? 'has' : 'have'}`;
  }

  if (string === 'need') {
    return `${prefixCount ? `${count} ` : ''}${count === 1 ? 'needs' : 'need'}`;
  }

  return `${prefixCount ? `${count} ` : ''}${count === 1 ? string : `${string}s`}`;
};

export const isInArray = (item, array) => {
  const [key, value] = Object.entries(item)[0];
  array.forEach((el) => {
    const isItem = el[key] === value;
    if (isItem) {
      return true;
    }
  });
  return false;
};

export const pickItemWithProbability = (items, probabilityLookup = {}) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  const lessonsWithProbabilities = Object.keys(probabilityLookup);
  const itemsNeedingProbabilities = items.filter((it) => lessonsWithProbabilities.indexOf(it.toString()) < 0);
  const probabilitiesSelectable = Object.entries(probabilityLookup).filter(
    (g) => items.indexOf(parseInt(g[0], 10)) >= 0
  );
  const fixedProbabilityTotal = probabilitiesSelectable.reduce((accumulator, a) => accumulator + a[1], 0);
  if (fixedProbabilityTotal >= 1) {
    console.error('fixed probability is greater than 1, cannot select');
    return null;
  }
  const remainingProbabilityTotal = 1 - fixedProbabilityTotal;
  const selectionValue = Math.random();
  const finalSelectionProbabilities = [];
  let accumulator = 0;
  probabilitiesSelectable.forEach((l) => {
    const prob = l[1];
    accumulator += prob;
    const id = parseInt(l[0], 10);
    finalSelectionProbabilities.push([id, accumulator]);
  });
  const evenDistProb = remainingProbabilityTotal / itemsNeedingProbabilities.length;
  itemsNeedingProbabilities.forEach((l) => {
    accumulator += evenDistProb;
    finalSelectionProbabilities.push([l, accumulator]);
  });
  let selection = null;
  for (let i = 0; i < finalSelectionProbabilities.length; i++) {
    let item = finalSelectionProbabilities[i];
    if (selection === null && selectionValue <= item[1]) {
      selection = parseInt(item[0], 10);
    }
  }
  return selection;
};

export const getRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isUndefined = (v) => v === undefined;
export const isNull = (v) => v === null;

export const isUndefinedOrNull = (v) => isUndefined(v) || isNull(v);

export const convertArrayToDisplayString = (array, limit = 0) => {
  if (limit === 1 || array.length === 1) {
    return array[0];
  } else if (limit > 0) {
    const limitedArray = array.slice(0, limit);
    const lastItem = limitedArray.splice(-1, 1);
    return `${limitedArray.join(', ')}, and ${lastItem}`;
  } else {
    const lastItem = array.splice(-1, 1);
    return `${array.join(', ')}, and ${lastItem}`;
  }
};

export const doXTimes = (x, func) => {
  for (let i = 0; i < x; i++) {
    func(i);
  }
};

export const mapXTimes = (x, func) => {
  const a = [];
  for (let i = 0; i < x; i++) {
    a.push(func(i));
  }
  return a;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function redactEmailParams(obj) {
  function redactUrl(url) {
    const urlObj = new URL(url);
    if (urlObj.searchParams.has('email')) {
      urlObj.searchParams.set('email', '<redacted>');
    }
    return urlObj.toString();
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        redactEmailParams(value); // Recur if it's an object
      } else if (key === 'url' && typeof value === 'string') {
        obj[key] = redactUrl(value); // Redact if it's a URL with the email param
      }
    }
  }
}
