import React from 'react';
import { sendRequestV1 } from '@src/helpers/ajaxHelpers';
import { BASEURL } from '@src/adapters';

const V1_URL = `${BASEURL}/api/v1`;

export const DeviceRiskSetter = () => {
  const onDeviceRiskReady = async () => {
    const response = await sendRequestV1({
      method: 'post',
      url: `${V1_URL}/device_risk`,
    });
    console.log({ response });
  };

  React.useEffect(() => {
    onDeviceRiskReady();
  }, []);

  return null;
};
