import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { useSearchParams } from 'react-router-dom';
import withWindowSize from './HOCS/withWindowSize';
import { withRouter } from '../main/utils';
import * as Actions from '../actions/index';
import { Config } from '../constants';
import { Body3, Body5, Page } from '@src/main/lib/nvstr-common-ui.es';
import StoreLogger from './DevTools/storeLogger';
import VerticalNav from './Nav/VerticalNav';
import { HorizontalNav } from './Nav/HorizontalNav';
import PerformanceRankingModal from '../containers/Notifications/modals/PerformanceRankingModal';
import Modal from '../components/misc/Modal';
import PortfolioOptimizationDataHelper from './Optimizer/PortfolioOptimizationDataHelper';
import InformationWindow from './UI/Tour/InformationWindow';
import ReloadAppBanner from './UI/ReloadAppBanner';
import MarginCallBanner from './UI/MarginCallBanner';
import WindowMonitor from './monitors/WindowMonitor';
import ActionMessageContainer from './ActionMessages/ActionMessageContainer';
import PortfolioOptimization from './Portfolio/PortfolioOptimization';
import InfoIconModal from './UI/InfoIconModal';
import FinishSettingUpAccountModal from '../components/modals/FinishSettingUpAccountModal';
import KarmaOverlay from './Karma/KarmaOverlay';
import {
  buildUserTrackingProperties,
  initializeAmplitudeClient,
  setTrackingUserProperties,
  setUTMUserProperties,
} from '../utils/tracking/amplitude';
import { UTM_KEYS, UTMTrackingKeys } from '../constants/types/utm';
import { setDefaultUserTrackingProperties } from '../actions/trackingActions';
// eslint-disable-next-line no-unused-vars
import { ErrorReloadPage } from './UI/ErrorReloadPage';
// eslint-disable-next-line no-unused-vars
import Icon from '../components/misc/Icon';
import { createQueryString, parseQueryString } from '../helpers/routerHelpers';
import {
  isCurrentUserOnboarding,
  returnCurrentUserId,
  hasCurrentUserAgreedBDTerms,
  isCurrentUserLiveTrading,
  hasAccessToExternalBrokerageFeature,
  getShouldSeeAlmostFinishedAccountSetupModalFromCurrentUserSession,
  getShouldSeeLinkBankAccountBannerFromCurrentUserSession,
  getShouldSeeDepositPromoModalFromCurrentUserSession,
} from '../helpers/currentUserHelpers';
import { scrollToTop } from '../helpers/pageHelpers';
import { isSignedIn } from '@src/main/containers/SignIn/utils/services';
import {
  getItemFromStorage,
  INTERNAL_STORAGE_KEYS,
  InternalStorage,
  setItemToStorage,
  STORAGE_KEYS,
} from '../utils/storage';
import { hasLinkedBankAccount } from '../selectors/bankAccount';
import { ROUTES } from '../constants/paths';
import { checkIfFurtherDocumentationIsRequired } from '../helpers/onboardingHelpers';
import Router from '../paths/Router';
// import OptimizerPanel from './Optimizer/OptimizerPanel/OptimizerPanel';
import itly from '../utils/itly/itly.service';
import { LoadingPage } from '../main/components/page';
import styled from 'styled-components';
import DepositPromoModal from '../components/modals/DepositPromoModal';
import PanelRenderer from '../paths/PanelRenderer';
import { Spacing } from '@src/main/components/ui';
import { LivePriceManager } from '@src/main/containers/Securities/LivePriceManager';
import { Container } from '../main/components/ui';
import { PagePanelRenderer } from '../paths/PagePanelRenderer';
import { MarketOpenManager } from '../main/Managers/MarketOpenManager';
import { PRODUCT_DISPLAY_FULL_NAME } from '../appConfig';
import { ComponentModal } from '@src/main/containers/ui/ComponentModal';
import { FlatButton } from '@src/main/components/buttons';
import Header from '@src/containers/Nav/verticalNavComponents/Header';
import { PublicPage } from '@src/main/components/page/PublicPage';

const Wrapper = (props) => props.children;

const AppBody = styled.div`
  background: ${({ theme }) => theme.themeColors.appBackground};
`;

const AppContainer = styled.div`
  select {
    option {
      color: ${({ theme }) => theme.themeColors.text};
      background-color: ${({ theme }) => theme.themeColors.component};
    }
  }
`;

const AppInitializing = () => {
  return (
    <div>
      <HorizontalNav forceLoading />
      <Container top={64}>
        <LoadingPage pageProps={{ transparentBackground: true }} />
      </Container>
      <Router hidden />
    </div>
  );
};

function UnsubscribedModal() {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    return () => {
      if (searchParams.has('unsubscribed')) {
        searchParams.delete('unsubscribed');
        setSearchParams(searchParams);
      }
    };
  }, []);

  return (
    <div>
      <Body5>You were unsubscribed successfully.</Body5>
      <Spacing top={8}>
        <Body5>You can re-subscribe at anytime by going to "Account Settings" then "Email Preferences".</Body5>
      </Spacing>
    </div>
  );
}

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noInternet: false,
      _isAuthed: false,
      _isAppInitializing: true,
      _isAppLoadFailure: false,
      _isUsingSpecialAccessToken: false,
    };

    this.IGNORE_BD_TERMS_STATUS_ROUTES = [
      '/terms',
      '/referral_rules',
      '/legal',
      '/margin_disclosure',
      '/terms_of_service',
      '/terms_of_service_nvstr_financial',
      '/privacy_policy',
      '/faq',
      '/about',
      '/viewpoint_guidelines',

      '/enterprise',
      '/whitelabel',
      '/white-label-brokerage-as-a-service',
      '/solutions',
      '/sales',
      '/sales_inquiry',
    ];

    this.FORCE_UNDOCK_VERTICAL_NAV_ROUTES = [
      '/share',
      '/about',

      '/enterprise',
      '/whitelabel',
      '/white-label-brokerage-as-a-service',
      '/solutions',
      '/sales',
      '/sales_inquiry',
    ];
  }

  appInitCalls = () => {
    this._handleResize();
    window.addEventListener('resize', this._handleResize);

    this._setVerticalNavDockStatus();
    this._checkForFeatureWaitlistResponse();
    this._checkForMembershipOptInResponse();

    this._getDataRequiredToMountApp();
    this._getAdditionalDataForApp();
  };

  componentDidMount() {
    try {
      // this will fail silently if not able to find nvstr mobile app
      window.location = `tornado://`;
    } catch (error) {
      console.error(error);
    }

    window.$ = $;

    const init = async () => {
      const {
        location: { href, pathname, search },
      } = window;
      const isPublicSecurityPage = href.includes('app/securities/');
      const isPublicThoughtsPage = href.includes('app/thoughts/');
      if (isPublicSecurityPage || isPublicThoughtsPage) {
        this.appInitCalls();
      } else {
        const { isAuthed } = await isSignedIn();
        if (isAuthed) {
          this.appInitCalls();
        } else {
          let route = pathname.slice(4, pathname.length);
          if (search !== '') {
            route += search;
          }
          setItemToStorage(INTERNAL_STORAGE_KEYS.NAVIGATION_INTENT, route);
          window.location = '/app/login';
        }
      }
    };
    init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState._isAuthed && this.state._isAuthed) {
      this._onAfterAuthenticationSuccess();
    }
    if (prevState._isAppInitializing && !this.state._isAppInitializing) {
      this._onAfterAppInitialization();
    }
    const location = this.props.location;
    const prevLocation = prevProps.location;
    if (prevLocation.pathname !== location.pathname || prevLocation.search !== location.search) {
      this._handleNavigationChange(prevLocation, location);
    }
    if (prevLocation.pathname !== location.pathname) {
      this._onNewPageNavigation(location);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  _refreshPage = () => {
    window.location.reload();
  };

  render() {
    if (this._isUsingSpecialAccessToken()) {
      return this._renderAppForSpecialAccessTokens();
    }

    if (this.state.noInternet) {
      return (
        <PublicPage>
          <Page width={'380px'}>
            <Body3>Could not load, please check your internet connection and try again.</Body3>
            <Container top={24}>
              <FlatButton fullWidth onClick={this._refreshPage}>
                Refresh
              </FlatButton>
            </Container>
          </Page>
        </PublicPage>
      );
    }

    return (
      <AppContainer className={`react-app-container ${this._returnNavColorClass()}`}>
        <StoreLogger />
        <WindowMonitor />
        <PortfolioOptimizationDataHelper
          isCurrentUserOnboarding={this._isOnboarding()}
          isAppInitialized={this._isAuthenticated() && !this._isAppInitializing()}
        />
        <PortfolioOptimization />

        <ReloadAppBanner />
        <MarginCallBanner />
        <ActionMessageContainer />

        <InfoIconModal />
        <Modal />
        <ComponentModal />
        <KarmaOverlay />
        {this._isAuthenticated() && <InformationWindow />}

        {this._isAppInitializing() ? <AppInitializing /> : this._renderAppInitialized()}
      </AppContainer>
    );
  }

  _getDataRequiredToMountApp = async () => {
    const { ok } = await this.props.actions.getIsMarketOpen();
    if (!ok) {
      const { ok } = await this.props.actions.getIsMarketOpen();
      if (ok) {
        return Promise.all([this._initGetCurrentUser()]);
      } else {
        this._setNoInternet();
      }
    } else {
      return Promise.all([this._initGetCurrentUser()]);
    }
  };

  _setNoInternet = () => {
    this.setState(() => ({
      noInternet: true,
    }));
  };

  _renderAppForSpecialAccessTokens = () => {
    return (
      <div className={'app-body-container'}>
        <HorizontalNav usingSpecialAccessToken />
      </div>
    );
  };

  _renderAppInitialized = () => {
    return (
      <div>
        <MarketOpenManager />
        <LivePriceManager />
        <div className={'app-body-container'}>
          {this._isAuthenticated() && <VerticalNav />}
          <AppBody style={{ height: '100%', width: this._returnAppWidth() }}>
            <Wrapper>
              <HorizontalNav forceEmptyNav={this.state._isAppLoadFailure} />
            </Wrapper>
            <PanelRenderer />
            <PagePanelRenderer />
            <Router />
          </AppBody>
        </div>
      </div>
    );
  };

  onChatBotLoad = () => {
    console.log('onChatBotLoad');
  };
  onChatBotLoadFail = () => {
    console.log('onChatBotLoadFail');
  };

  _onAfterAuthenticationSuccess = () => {
    this._checkBDTermsStatus();
  };

  _checkLiveTradingApplicationStatus = async () => {
    const { navigate } = this.props;

    const isUserActionRequired = await checkIfFurtherDocumentationIsRequired(true);
    const socureSubmissionRoutes = [
      ROUTES.RESUBMIT_ADDRESS.build(),
      ROUTES.UPLOAD_REQUIRED_DOCUMENTS.build(),
      ROUTES.LIVE_ACCOUNT_SUBMISSION_STATUS.build(),
    ];

    const route = window.location.pathname.split('/app')[1];
    const isOnSubmissionRoute = socureSubmissionRoutes.includes(route);

    if (isUserActionRequired && !isOnSubmissionRoute) {
      navigate(ROUTES.LIVE_ACCOUNT_SUBMISSION_STATUS.build());
    }
  };

  _onAfterAppInitialization = () => {
    this._checkLiveTradingApplicationStatus();

    this._checkForRankingNotificationParam();
    this._checkForUnsubscribeParam();

    this._launchAppBanners();
    this._launchAppStartModals();
  };

  // only use this to get the current user on mount
  _initGetCurrentUser = () =>
    this.props.actions.fetchCurrentUserQuickInfo().then((user) => {
      if (user && !!user.user_id) {
        const amplitudeUserId = user.analytics_id;
        initializeAmplitudeClient(amplitudeUserId);

        const defaultTrackingProperties = {};
        UTM_KEYS.forEach((key) => {
          const trackingKey = UTMTrackingKeys[key];
          if (key in user) {
            defaultTrackingProperties[trackingKey] = user[key];
          } else {
            defaultTrackingProperties[trackingKey] = null;
          }
        });
        const whiteLabelKey = 'white_label_partner';
        if (whiteLabelKey in user) {
          defaultTrackingProperties['White Label Partner'] = user[whiteLabelKey];
        }
        setDefaultUserTrackingProperties(defaultTrackingProperties);
        const userProperties = buildUserTrackingProperties(user, user);

        itly.identify(user?.analytics_uuid, {}, {});
        setTrackingUserProperties(userProperties);
        setUTMUserProperties(user);

        this._setIsAuthed();
        this._getRequiredDataAfterGetCurrentUserData(user);
      } else {
        this._setAppIsInitialized();
      }

      const response = user && user.user_id ? null : user;
      const didFailForAuth =
        response &&
        response.error &&
        response.error.response &&
        response.error.response.data &&
        response.error.response.data.error &&
        response.error.response.data.error === 'Please sign in to continue.';
      if (didFailForAuth) {
        if (window.location.pathname === '/api/orders/approve') {
          this._setIsUsingSpecialAccessToken();
        }
      }
    });

  _getAdditionalDataForApp = () => {
    this.props.actions.fetchCollectives();
    this.props.actions.getStaticCopy();
  };

  _getRequiredDataAfterGetCurrentUserData = (currentUser) => {
    const { is_account_closed: isAccountClosed } = currentUser;

    if (isAccountClosed) {
      this._getRequiredDataAfterGetCurrentUserForClosedAccount();
    } else {
      this._getRequiredDataAfterGetCurrentUserDataForOpenAccount(currentUser);
    }
  };

  _getRequiredDataAfterGetCurrentUserForClosedAccount = () => {
    return Promise.all([this.props.actions.getFooterPartial(), this.props.actions.getIsMarketOpen()])
      .then((responses) => {
        this._setAppIsInitialized();
      })
      .catch((responses) => {
        console.error('Caught error fetching data required to mount app', responses);
      });
  };

  _getRequiredDataAfterGetCurrentUserDataForOpenAccount = (currentUser) => {
    this.props.actions.fetchAllLearnLessons();
    this.props.actions.fetchCompletedLearnLessons();
    this.props.actions.fetchNextLearnLesson();

    this.props.actions.getBankAccounts();

    return Promise.all([
      // fetching nav banner data before mounting app is required to minimize performance impact when it async loads in while the rest of the app is mounting since it forces the app to resize so it can be fixed to top

      // fetching rewards data before app mounts so vertical nav knows if it should show the tab or not
      this.props.actions.getRewardsData(),

      this.props.actions.getFooterPartial(),

      // initial user data
      this.props.actions.getCurrentUserIdeas(),
      this.props.actions.fetchThoughtLeaders(),
      this.props.actions.getImportConnectionsNetworks(),
      this.props.actions.getCurrentUserConnections(),

      this.props.actions.fetchAllViewpointData(),
    ])
      .then((responses) => {
        this._setAppIsInitialized();

        if (hasAccessToExternalBrokerageFeature(this._returnCurrentUser())) {
          // loading external positions from plaid linked external brokerage accounts
          // move it if it impacts performance
          this.props.actions.refreshCurrentUserExternalPositionsInfo();
        }

        if (isCurrentUserOnboarding(currentUser)) {
          this.props.navigate(ROUTES.ONBOARDING_HOME.build());
        }
      })
      .catch((responses) => {
        console.error('Caught error fetching data required to mount app', responses);
      });
  };

  _isUsingSpecialAccessToken = () => this.state._isUsingSpecialAccessToken;

  _isAppInitializing = () => this.state._isAppInitializing;

  _returnAppWidth = () => {
    const verticalNavWidth = 185 * (this._isVerticalNavShowing() ? 1 : 0);
    const windowWidth = this.props.windowWidth;
    const appHeight = windowWidth - verticalNavWidth;
    return windowWidth > 0 && !this._isAppInitializing() ? `${appHeight}px` : '100%';
  };

  _returnNavColorClass = () => 'dark-nav-theme'; // hard coded because A/B concluded dark was better

  _returnCurrentUser = () => this.props.currentUser;

  _isUserAuthed = () => this.state._isAuthed;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _isAuthenticated = () => 'user_id' in this._returnCurrentUser(); // Still needs more enhancements in the future

  _isOnboarding = () => isCurrentUserOnboarding(this._returnCurrentUser());

  _hasAgreedToBdTerms = () => hasCurrentUserAgreedBDTerms(this._returnCurrentUser());

  _handleNavigationChange = (prevLocation) => {
    this._setVerticalNavDockStatus(prevLocation);
    this._checkBDTermsStatus();
  };

  _handleResize = () => {
    if (!this._doesRouteBeForcedUndocked()) {
      this._updateVerticalNavExpansionLock();
    }
  };

  _onNewPageNavigation = (prevLocation, location) => {
    scrollToTop();
  };

  _launchAppBanners = () => {
    const { hasLinkedBankAccount } = this.props;
    const wasShowFundBankAccountBannerDismissedValue = getItemFromStorage(
      STORAGE_KEYS.DISMISSED_FUND_BANK_ACCOUNT_BANNER
    );
    const wasShowFundBankAccountBannerDismissed = wasShowFundBankAccountBannerDismissedValue === true;
    const shouldShowFundBankAccountBanner = getShouldSeeLinkBankAccountBannerFromCurrentUserSession(
      this._returnCurrentUser()
    );

    if (
      !this._isOnboarding() &&
      !hasLinkedBankAccount &&
      shouldShowFundBankAccountBanner &&
      !wasShowFundBankAccountBannerDismissed
    ) {
      const text = 'Finish setting up your account by linking your bank account to transfer funds and start investing.';
      const banner = {
        text,
        link: '/portfolio/funding',
        link_text: 'Continue',
        status: 'FUND_BANK_ACCOUNT',
      };

      this.props.actions.showBanner(banner);
    }
  };

  _launchAppStartModals = () => {
    const { isLiveTrading } = this.props;
    const _hideModal = this.props.actions.hideModal;
    const _logMetricsTrackingEvent = this.props.actions.logMetricsTrackingEvent;

    const isEligibleForDepositPromo = getShouldSeeDepositPromoModalFromCurrentUserSession(this._returnCurrentUser());

    const wasShowFinishSettingUpAccountModalDismissedValue = getItemFromStorage(
      STORAGE_KEYS.DISMISSED_FINISH_SETTING_UP_ACCOUNT_MODAL
    );
    const wasShowFinishSettingUpAccountModalDismissed = wasShowFinishSettingUpAccountModalDismissedValue === true;
    const shouldShowFinishSettingUpAccountModal = getShouldSeeAlmostFinishedAccountSetupModalFromCurrentUserSession(
      this._returnCurrentUser()
    );

    const handleDepositPromoDismiss = () => {
      _hideModal();

      const event = 'Dismiss Deposit Promo Modal';
      _logMetricsTrackingEvent(event);
    };

    if (isEligibleForDepositPromo) {
      const modal = {
        customBody: <DepositPromoModal />,
        dismissable: true,
        overrideOverlayDismiss: handleDepositPromoDismiss,
        overrideDismiss: handleDepositPromoDismiss,
      };
      this.props.actions.showModal(modal);
      return;
    }

    const handleDismiss = () => {
      _hideModal();

      const event = 'Dismiss Finish Setting Up Your Account Modal';
      _logMetricsTrackingEvent(event);

      setItemToStorage(STORAGE_KEYS.DISMISSED_FINISH_SETTING_UP_ACCOUNT_MODAL, true);
    };

    if (
      isLiveTrading &&
      !this._isOnboarding() &&
      !hasLinkedBankAccount &&
      shouldShowFinishSettingUpAccountModal &&
      !wasShowFinishSettingUpAccountModalDismissed
    ) {
      const modal = {
        contentComponent: <FinishSettingUpAccountModal />,
        dismissable: true,
        overrideOverlayDismiss: handleDismiss,
        overrideDismiss: handleDismiss,
      };
      this.props.actions.showModal(modal);
    }
  };

  _forceNavigateToBDTermsForm = () => {
    this._saveDestinationURL();
    this.props.navigate('/terms');
  };

  _checkBDTermsStatus = () => {
    if (!this._hasAgreedToBdTerms() && this._isUserAuthed()) {
      const pathname = this.props.location.pathname;
      const query = parseQueryString(this.props.location.search);
      if (query.panel) {
        this._forceNavigateToBDTermsForm();
      }
      if (!this.IGNORE_BD_TERMS_STATUS_ROUTES.includes(pathname)) {
        this._forceNavigateToBDTermsForm();
      }
    }
  };

  _saveDestinationURL = () => {
    const pathname = this.props.location.pathname;
    const query = this.props.location.search;
    const url = pathname + query;
    this.props.actions.saveToAppStorage({ destinationURL: url });
  };

  _setVerticalNavDockStatus = (prevLocation) => {
    // used to prevent VNav from breaking rails page media queries
    const routesToUndockVNav = this.FORCE_UNDOCK_VERTICAL_NAV_ROUTES;
    const desktopToMobileBreakPoint = Config.desktopMaxWidth;
    const isMobileSizeForUndockedVNav = this.props.windowWidth < desktopToMobileBreakPoint;
    const prevRouteShouldUndock =
      prevLocation && routesToUndockVNav.some((route) => route.indexOf(prevLocation.pathname) >= 0);
    const shouldDockVNav = !this._isVerticalNavDocked() && prevRouteShouldUndock && !isMobileSizeForUndockedVNav;

    if (this._shouldNavBeForcedUndocked()) {
      this.props.actions.forceUndockVerticalNav();
      window.$('.react-nav-container .container').css('width', '100%');
    } else if (shouldDockVNav && prevRouteShouldUndock) {
      this.props.actions.releaseForceUndockVerticalNav();
    }
  };

  _doesRouteBeForcedUndocked = () => {
    if (window.location.pathname === '/') {
      return false;
    }

    const currentRouteShouldUndock =
      window.location.pathname !== '/' &&
      this.FORCE_UNDOCK_VERTICAL_NAV_ROUTES.some((route) => window.location.pathname.indexOf(route) >= 0);
    return currentRouteShouldUndock;
  };

  _shouldNavBeForcedUndocked = () => this._doesRouteBeForcedUndocked();

  _isVerticalNavShowing = () =>
    this._isVerticalNavDocked() && this._isAuthenticated() && !this._isAppInitializing() && !this._isOnboarding();

  _isVerticalNavDocked = () => this.props.nav.alwaysOpen;

  _updateVerticalNavExpansionLock = () => {
    const screenWidth = this.props.windowWidth;
    const isVerticalNavDocked = this._isVerticalNavDocked();
    const mobileBreakPoint = 976;

    if (screenWidth <= mobileBreakPoint) {
      if (isVerticalNavDocked) {
        this.props.actions.toggleAlwaysOpenVerticalNav();
      }
    }
    if (screenWidth > mobileBreakPoint && !this._isOnboarding()) {
      if (!isVerticalNavDocked) {
        this.props.actions.toggleAlwaysOpenVerticalNav();
      }
    }
  };

  _checkForFeatureWaitlistResponse = () => {
    const query = this.props.location.search;
    const queryObj = parseQueryString(query);

    const isFeatureWaitlistResponse = queryObj.featureWaitlistResponse === 'true';
    if (isFeatureWaitlistResponse) {
      const component = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          {"Your response has been recorded. We'll let you know when this feature becomes available."}
        </div>
      );
      const modal = {
        contentComponent: component,
        dismissable: true,
        overrideDismiss: () => {
          delete queryObj.featureWaitlistResponse;
          const paramRemovedQuery = createQueryString(queryObj);
          this.props.navigate(this.props.location.pathname + paramRemovedQuery);
        },
      };
      this.props.actions.showModal(modal);
    }
  };

  _checkForUnsubscribeParam = () => {
    const query = this.props.location.search;
    const queryObj = parseQueryString(query);
    const isParamPresent = Object.keys(queryObj).includes('unsubscribed');
    if (isParamPresent) {
      const modal = {
        contentComponent: <UnsubscribedModal />,
        dismissable: true,
        size: 'wide',
        overrideDismiss: () => {
          delete queryObj.show_ranking_as_of;
          const paramRemovedQuery = createQueryString(queryObj);
          this.props.navigate(this.props.location.pathname + paramRemovedQuery);
        },
      };
      this.props.actions.showModal(modal);
    }
  };

  _checkForRankingNotificationParam = () => {
    const query = this.props.location.search;
    const queryObj = parseQueryString(query);
    const shouldShowRankingNotifInModal = 'show_ranking_as_of' in queryObj;
    if (shouldShowRankingNotifInModal) {
      const notificationDate = queryObj.show_ranking_as_of;
      const modal = {
        contentComponent: <PerformanceRankingModal notificationDate={notificationDate} />,
        dismissable: true,
        size: 'wide',
        overrideDismiss: () => {
          delete queryObj.show_ranking_as_of;
          const paramRemovedQuery = createQueryString(queryObj);
          this.props.navigate(this.props.location.pathname + paramRemovedQuery);
        },
      };
      this.props.actions.showModal(modal);
    }
  };

  _checkForMembershipOptInResponse = () => {
    const query = this.props.location.search;
    const queryObj = parseQueryString(query);

    const isMembershipOptInResponse = queryObj.membershipOptIn === 'true';
    const isMembershipOptOutResponse = queryObj.membershipOptOut === 'true';
    if (isMembershipOptInResponse) {
      const component = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          Success! Your {PRODUCT_DISPLAY_FULL_NAME} membership has been activated.
        </div>
      );
      const modal = {
        contentComponent: component,
        dismissable: true,
        overrideDismiss: () => {
          delete queryObj.membershipOptIn;
          const paramRemovedQuery = createQueryString(queryObj);
          this.props.navigate(this.props.location.pathname + paramRemovedQuery);
        },
      };
      this.props.actions.showModal(modal);
    } else if (isMembershipOptOutResponse) {
      const component = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          {
            'You have successfully opted out of the membership plan. If you change your mind, please contact customer support.'
          }
        </div>
      );
      const modal = {
        contentComponent: component,
        dismissable: true,
        overrideDismiss: () => {
          delete queryObj.membershipOptOut;
          const paramRemovedQuery = createQueryString(queryObj);
          this.props.navigate(this.props.location.pathname + paramRemovedQuery);
        },
      };
      this.props.actions.showModal(modal);
    }
  };

  _setIsAuthed = () => {
    this.setState(() => ({
      _isAuthed: true,
    }));
  };

  _setAppIsInitialized = () => {
    this.setState(() => ({
      _isAppInitializing: false,
    }));
  };

  _setIsUsingSpecialAccessToken = () => {
    this.setState(() => ({
      _isUsingSpecialAccessToken: true,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    banner: state.banner,
    currentUser: state.currentUser,
    nav: state.nav,
    hasLinkedBankAccount: hasLinkedBankAccount(state),
    isLiveTrading: isCurrentUserLiveTrading(state.currentUser),
    _state_isAuthed: state.authentication.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  withWindowSize,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
export default composedComponent;
