import React from 'react';
import { useLocation, Route, BrowserRouter, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { TrackIteratively } from '../utils/itly';
import useColorTheme from '../main/hooks/util/useColorTheme';
import { breakpoints, colorPalette, themes } from '../main/lib/nvstr-utils.es';
import { useScreenSize } from '../main/hooks/util';
import ErrorBoundary from './Errors/ErrorBoundary';
import { LearnAndEarnMoreInfoPublic, LearnAndEarnShare } from '../main/pages/LearnAndEarnShare';
import { FONT } from '@src/appConfig';
import { SignIn } from '@src/main/pages/SignIn';
import { SignUp } from '@src/main/pages/SignUp';
import { ForgotPassword } from '@src/main/pages/ForgotPassword';
import { ResetPassword } from '@src/main/pages/ResetPassword';
import { PlaidReinitialization } from '../main/pages/funding/interstitial/PlaidReinitialization';
import { logMetricsTrackingEvent } from '@src/actions';
import { getPageNameFromPathname } from '@src/constants/contextTracking';
import { useSelector } from 'react-redux';
import { DeviceRiskSetter } from '@src/main/containers/SignIn/DeviceRiskSetter';

const AppThemeWrapper = styled.div`
  .pro-text-color,
  .pro-text-color * {
    color: ${({ theme }) => theme.themeColors.proColor};
  }
  .con-text-color,
  .con-text-color * {
    color: ${({ theme }) => theme.themeColors.conColor};
  }
  .error-text-color,
  .error-text-color * {
    color: ${({ theme }) => theme.themeColors.error};
  }
  .buy-text-color,
  .buy-text-color * {
    color: ${({ theme }) => theme.themeColors.positive};
  }
  .sell-text-color,
  .sell-text-color * {
    color: ${({ theme }) => theme.themeColors.negative};
  }
  .increase-change-text-color,
  .increase-change-text-color * {
    color: ${({ theme }) => theme.themeColors.positive};
  }
  .decrease-change-text-color,
  .decrease-change-text-color * {
    color: ${({ theme }) => theme.themeColors.negative};
  }
  .disclaimer-text-color {
    color: ${({ theme }) => theme.themeColors.border};

    a {
      color: ${({ theme }) => theme.themeColors.border};
    }
  }
  .alternate-panel-bg-color {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
  }
  .eye-catching-bg {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
  .react-page-container,
  .main-bg-color-match {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }
  footer.new.grey-footer {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
    border-top: 1px solid;
    border-color: ${({ theme }) => theme.themeColors.border};
  }
  textarea:focus {
    border-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
  .border-accent {
    border-color: ${({ theme }) => theme.themeColors.border};
  }
  .component-bg {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }
  .pro-button-color {
    background-color: ${({ theme }) => theme.themeColors.proColor};
  }
  .con-button-color {
    background-color: ${({ theme }) => theme.themeColors.conColor};
  }

  .secondary-text-color {
    color: ${({ theme }) => theme.themeColors.secondaryText};
    * {
      color: ${({ theme }) => theme.themeColors.secondaryText};
    }
  }
`;
const GlobalStyles = createGlobalStyle`
  body {
    min-height: 100vh;
    background-color: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};
    font-family: ${({ theme }) => FONT.normal};
  }
  
  #root {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
  }
  
  span,
  div,
  a,
  div,
  p {
    font-family: inherit;
  }
  
  span,
  div,
  p {
    color: inherit;
  }
  
  a {
    color: ${({ theme }) => theme.themeColors.text};
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.themeColors.text};
    font-family: ${({ theme }) => FONT.bold};
    font-weight: 600;
  }
  
  .bold,
  b {
    color: inherit;
    font-family: ${({ theme }) => FONT.bold};
    font-weight: 600;
    
    * {
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
    }
  }

  .thin {
    font-family: ${({ theme }) => FONT.thin};
    font-weight: 100;
  }
  
  textarea,
  select,
  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 0;
  }
`;

const GOOGLE_ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
const FB_PIXEL_ID = import.meta.env.VITE_FB_PIXEL_ID;
const TIK_TOK_PIXEL_ID = import.meta.env.VITE_TIK_TOK_PIXEL_ID || '0';
const STACK_ADAPT_PIXEL_ID = import.meta.env.VITE_STACK_ADAPT_PIXEL_ID || '0';
const VITE_BRANCH_KEY = import.meta.env.VITE_BRANCH_KEY || '0';

class DynamicScripts extends React.PureComponent {
  componentDidMount() {
    try {
      (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
          i[r] ||
          function() {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

      ga('create', GOOGLE_ANALYTICS_ID, 'auto');

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', GOOGLE_ANALYTICS_ID);

      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', FB_PIXEL_ID);

      !(function(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          'page',
          'track',
          'identify',
          'instances',
          'debug',
          'on',
          'off',
          'once',
          'ready',
          'alias',
          'group',
          'enableCookie',
          'disableCookie',
        ]),
          (ttq.setAndDefer = function(t, e) {
            t[e] = function() {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function(t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function(e, n) {
            var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            n = document.createElement('script');
            (n.type = 'text/javascript'), (n.async = !0), (n.src = i + '?sdkid=' + e + '&lib=' + t);
            e = document.getElementsByTagName('script')[0];
            e.parentNode.insertBefore(n, e);
          });

        ttq.load(TIK_TOK_PIXEL_ID);
        ttq.page();
      })(window, document, 'ttq');

      !(function(s, a, e, v, n, t, z) {
        if (s.saq) return;
        n = s.saq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!s._saq) s._saq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '1.0';
        n.queue = [];
        t = a.createElement(e);
        t.async = !0;
        t.src = v;
        z = a.getElementsByTagName(e)[0];
        z.parentNode.insertBefore(t, z);
      })(window, document, 'script', 'https://tags.srv.stackadapt.com/events.js');
      saq('ts', STACK_ADAPT_PIXEL_ID);

      // load Branch
      (function(b, r, a, n, c, h, _, s, d, k) {
        if (!b[n] || !b[n]._q) {
          for (; s < _.length; ) c(h, _[s++]);
          d = r.createElement(a);
          d.async = 1;
          d.src = 'https://cdn.branch.io/branch-latest.min.js';
          k = r.getElementsByTagName(a)[0];
          k.parentNode.insertBefore(d, k);
          b[n] = h;
        }
      })(
        window,
        document,
        'script',
        'branch',
        function(b, r) {
          b[r] = function() {
            b._q.push([r, arguments]);
          };
        },
        { _q: [], _v: 1 },
        'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode'.split(
          ' '
        ),
        0
      );
      // init Branch
      window.branch.init(VITE_BRANCH_KEY);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return null;
  }
}

const PageTracking = () => {
  const location = useLocation();
  React.useEffect(
    () => {
      try {
        const { pathname } = location;
        const trackingScreenName = getPageNameFromPathname(pathname);
        const properties = {
          location: location.pathname,
          url: location.pathname,
          url_query: location.search,
          'Screen Name': trackingScreenName,
        };
        TrackIteratively.viewed.PAGE_VIEWED.send(properties);
        logMetricsTrackingEvent('View Screen', properties)();
      } catch (e) {
        console.error('Could not track page view');
      }
    },
    [location.pathname]
  );

  return null;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Main = () => {
  const colorThemeName = useColorTheme(true);
  const colorTheme = useColorTheme();
  const screenSize = useScreenSize();
  const theme = {
    colorTheme,
    colorThemeName,
    ...colorPalette,
    colorPalette,
    breakpoints,
    themeColors: colorTheme,
    screenSize,
    themes,
  };
  console.log({ theme });
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <BrowserRouter basename={'/app'}>
          <ErrorBoundary>
            <AppThemeWrapper className="app-container">
              <DynamicScripts />
              <DeviceRiskSetter />
              <PageTracking />
              <SentryRoutes>
                <Route path="/learn-and-earn-more-info-share" exact element={<LearnAndEarnMoreInfoPublic />} />
                <Route path="/learn-and-earn-share/:name" exact element={<LearnAndEarnShare />} />
                <Route path="/login" exact element={<SignIn />} />
                <Route path="/signup" exact element={<SignUp />} />
                <Route path="/users/sign_up" exact element={<SignUp />} />
                <Route path="/forgot_password" exact element={<ForgotPassword />} />
                <Route path="/reset_password" exact element={<ResetPassword />} />
                <Route path="/plaid" element={<PlaidReinitialization />} />
                <Route path="/*" element={<App />} />
              </SentryRoutes>
            </AppThemeWrapper>
          </ErrorBoundary>
        </BrowserRouter>
      </>
    </ThemeProvider>
  );
};

export default Main;
